import React, { useEffect, useRef } from 'react';
import './style.scss';
import { Fab } from '@mui/material';
import { SpatialTracking as SpatialTrackingIcon } from '@mui/icons-material';
import { useWebTitle } from 'utils/useWebTitle';

const waves = [
  "▁",
  "▃",
  "▅",
  "▇",
  "█",
];
const rotating = [
  "▞",
  "▚"
]

const step = Math.round(256 / waves.length);

const renderTitle = (state: boolean, data: Uint8Array) => {
  let s = "";
  const sliceWidth = Math.round(data.length / 16);
  for (let i = 0; i < data.length; i += sliceWidth) {
    const tempArray = data.slice(i, i + sliceWidth);
    const avg = tempArray.reduce((p, c, i, a) => p + c, 0) / tempArray.length;
    s += waves[Math.round(avg / step)];
  }
  return `${state ? rotating[0] : rotating[1]} ${s}`;
}

function Comp() {
  const [, setTitle] = useWebTitle();
  const sourceRef = useRef<MediaElementAudioSourceNode | null>(null);
  const booleanFlipRef = useRef<boolean>(false);
  const audioContextRef = useRef<AudioContext>(null!);
  const audioRef = useRef<HTMLAudioElement>(null!);
  const canvasRef = useRef<HTMLCanvasElement>(null!);
  const viewRef = useRef<HTMLDivElement>(null!);
  const currentTimeRef = useRef<DOMHighResTimeStamp>(0);
  const currentFrameRef = useRef<number>(0);


  useEffect(() => {
    if (!sourceRef.current) {
      audioContextRef.current = new AudioContext();
      sourceRef.current = audioContextRef.current.createMediaElementSource(audioRef.current);
      const analyser = audioContextRef.current.createAnalyser();
      sourceRef.current.connect(analyser);
      analyser.connect(audioContextRef.current.destination);

      if (canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d')!;
        const data = new Uint8Array(analyser.frequencyBinCount);

        const draw = function (hRes: DOMHighResTimeStamp) {
          currentFrameRef.current = requestAnimationFrame(draw);
          analyser.getByteTimeDomainData(data);
          const width = canvas.width;
          const height = canvas.height;
          ctx.clearRect(0, 0, width, height);
          ctx.beginPath();
          let x = 0;
          const sliceWidth = (width * 1.0) / data.length;
          for (let i = 0; i < data.length; i++) {
            const v = data[i] / 128.0;
            const y = (v * height) / 2;
            if (i === 0) {
              ctx.moveTo(x, y);
            } else {
              ctx.lineTo(x, y);
            }
            x += sliceWidth;
          }
          ctx.stroke();
          if ((hRes - currentTimeRef.current) / 1000 >= 1) {
            booleanFlipRef.current = !booleanFlipRef.current;
            var waves = renderTitle(booleanFlipRef.current, data);
            setTitle(waves);
            currentTimeRef.current = hRes;
          }
        }
        draw(currentTimeRef.current);
      }

    }
    return () => {
      cancelAnimationFrame(currentFrameRef.current);
    }
  }, [setTitle]);
  useEffect(() => {
    audioRef.current.volume = 0.5;
    audioRef.current.autoplay = true;
    audioRef.current.loop = true;
    audioRef.current.preload = 'auto';
    audioRef.current.hidden = true;
    audioRef.current.controls = true;
    canvasRef.current.width = viewRef.current.clientWidth;
    canvasRef.current.height = viewRef.current.clientHeight;
    return () => { };
  }, []);
  return (
    <div className="TruE" ref={viewRef}>
      <Fab variant="extended" onClick={() => { audioContextRef.current?.resume(); audioRef.current.play(); }}>
        <SpatialTrackingIcon sx={{ mr: 1 }} />
        Click me please!
      </Fab>
      <canvas className='audio-waves-canvas' ref={canvasRef} />
      <audio
        ref={audioRef}
        title="TruE"
      >
        <source src="/dist/audios/TruE.mp3" type="audio/mpeg"></source>
      </audio>
    </div>
  );
}

export default React.memo(Comp);
