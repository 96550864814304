import React from 'react';
import './style.scss';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { SettingsInputAntenna as SettingsInputAntennaIcon, MusicNote as MusicNoteIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';

function Comp() {
  return (
    <Box
      sx={{ width: 250 }}
      role="presentation"
    >
      <List>

        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <SettingsInputAntennaIcon />
            </ListItemIcon>
            <ListItemButton component={Link} to="/f2f">
              <ListItemText primary="F2F" />
            </ListItemButton>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <MusicNoteIcon />
            </ListItemIcon>
            <ListItemButton component={Link} to="/true">
              <ListItemText primary="TruE" />
            </ListItemButton>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}

export default React.memo(Comp);
