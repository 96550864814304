import React from 'react';
import { Routes, Route, } from "react-router-dom";
import './App.scss';

import LandingPage from 'pages/landing';
import NoMatch from 'pages/no-match';
import TruE from 'pages/true';
import Layout from 'containers/layout';

function App() {
  return (
    <div className="site">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="true" element={<TruE />} />

          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

export default React.memo(App);
