import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Container, Drawer, } from '@mui/material';
import SideBar from 'components/side-bar';
import Header from 'components/header';
import { useWebTitle } from 'utils/useWebTitle';


function Layout() {
    const [, setTitle] = useWebTitle();
    const [state, setState] = useState(false);

    setTitle("φ(*￣0￣) - `(*>﹏<*)′");

    return (
        <>
            <Drawer
                anchor="left"
                open={state}
                onClose={() => setState(false)}
            >
                <SideBar />
            </Drawer>
            <Header onSideBarRequest={() => setState(!state)}/>
            <Container>
                <Outlet />
            </Container>
        </>
    );
}

export default React.memo(Layout);
