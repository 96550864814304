import React from 'react';
import './style.scss';
import { useWebTitle } from 'utils/useWebTitle';

function Page() {
    const [, setTitle] = useWebTitle();
    setTitle("φ(*￣0￣) - `(*>﹏<*)′");
    return (
        <>
            <div className="landing marquee">
                <p className='love'>
                (* ￣3)(ε￣ *) ~~Make with my TruE LOVE~~ ᓚᘏᗢ
                </p>
            </div>
        </>
    );
}

export default React.memo(Page);
