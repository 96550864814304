import React from 'react';
import TruE from 'components/true';
import './style.scss';

function Page() {
    return (
        <div className='true-page'>
            <TruE />
        </div>
    );
}

export default React.memo(Page);
